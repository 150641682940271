import React, { useState } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import carbon from '../assets/images/carbon-fiber.jpg';
import TechnologyCard from '../components/TechnologyCard';
import NavPages from '../components/NavPages';
import SEO from '../components/SEO';

const TechnologyPageStyled = styled.div`
  width: 100%;
  .technology-header {
    background-image: linear-gradient(
        to bottom,
        var(--whiteTrans),
        var(--whiteTrans)
      ),
      url(${carbon});
  }
  .technology-container {
    max-width: var(--wrapper);
    width: 100%;
    margin: 4rem auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: inherit;
    justify-items: stretch;
    gap: 2rem;
    @media (max-width: 1023px) {
      grid-template-columns: 1fr;
    }
    .full-width {
      grid-column: span 2;
      @media (max-width: 1023px) {
        grid-column: span 1;
      }
    }
  }
`;

export default function TechnologyPage({ data: { technology } }) {
  return (
    <>
      <SEO title="Learn about Bont Rowing technology" />
      <TechnologyPageStyled>
        <div className="wrapper">
          <section className="page-header technology-header">
            <div>
              <h2>{technology.nodes[0].title}</h2>
            </div>
          </section>
          <section className="page-description technology-description">
            <p>{technology.nodes[0].description}</p>
          </section>
          <section className="technology-container">
            {technology.nodes[0].technologyPages.map((item, index) => (
              <TechnologyCard
                key={index}
                dataItem={index}
                title={item.title}
                description={item.description}
                subTitle={item.subTitle}
                callToAction={item.callToAction}
                href={
                  item.href ? item.href : `/technology/${item.slug.current}`
                }
                backgroundImage={item.backgroundImage.asset.fluid.srcWebp}
                slug={item.slug.current}
                hasPage={!!item._rawContent}
              />
            ))}
          </section>
        </div>
        <NavPages />
      </TechnologyPageStyled>
    </>
  );
}

export const query = graphql`
  query TechnologyQuery {
    technology: allSanityTechnology {
      nodes {
        title
        shortDescription
        technologyPages {
          title
          subTitle
          description
          callToAction
          href
          _rawContent
          slug {
            current
          }
          backgroundImage {
            asset {
              fluid(maxWidth: 1000) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
  }
`;
