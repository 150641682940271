import React, { useState } from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { Link } from 'gatsby';
import { VscClose } from '@react-icons/all-files/vsc/VscClose';

const TechnologyCardStyled = styled.div`
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  min-width: 100%;
  min-height: 540px;
  border-radius: 8px;
  color: var(--white);
  text-align: center;
  padding: 7rem 5rem;
  transition: 500ms ease-in-out;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  &.open {
    background-size: 4000%;
    background: var(--black);
    background-image: linear-gradient(
      to bottom,
      var(--black),
      var(--black)
    ) !important;
    background-position: center top;
  }
  .narrow {
    max-width: 450px;
    margin: 2rem auto 0;
  }
  a.page-link {
    color: var(--watermelon);
    margin: 2rem auto 0;
    display: block;
    text-decoration: none;
  }
  .description {
    background: var(--black);
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    padding: 4rem 3rem;
    transition: 200ms ease-in;
    overflow-y: auto;
    span {
      position: relative;
      display: grid;
      align-items: center;
      height: 100%;
    }
    svg {
      position: absolute;
      top: 1rem;
      right: 1rem;
      font-size: 3rem;
    }
    a.boa-guarantee {
      color: var(--white);
    }
    p {
      max-width: calc(var(--wrapper) / 2);
      margin-left: auto;
      margin-right: auto;
    }
    @media (max-width: 1023px) {
      padding: 2rem;
    }
  }
  .description.reveal {
    opacity: 1;
  }
  .cta {
    display: inline-block;
    cursor: pointer;
    color: var(--watermelon);
    padding: 2rem;
    line-height: 150%;
    text-decoration: none;
  }
`;

export default function TechnologyCard(props) {
  const [descState, toggleDescription] = useState(false);
  function toggleDesc() {
    toggleDescription(!descState);
  }
  console.dir(props);
  return (
    <TechnologyCardStyled
      style={{
        backgroundImage: `linear-gradient(to bottom, var(--black), rgba(0,0,0,0.6)),url(${
          props.backgroundImage ? props.backgroundImage : ''
        })`,
      }}
      // onClick={props.hasPage ? undefined : toggleDesc}
      className={props.dataItem === 4 ? 'full-width' : ''}
    >
      <h4>{props.title}</h4>
      <p className="narrow">
        {props.subTitle ? props.subTitle : props.description}
      </p>
      {props.hasPage && (
        <Link className="page-link" to={props.href}>
          {props.callToAction}
        </Link>
      )}
    </TechnologyCardStyled>
  );
}
